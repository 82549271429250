<template>
  <b-row>
    <b-col md="12">
      <b-card
        title="Filtrele"
      >
        <b-form-group
          label="Anahtar Kelime"
          label-for="keywords"
        >
          <b-form-input
            id="keywords"
            v-model="dataQuery.keywords"
            placeholder="Şase no, Araç no, Marka, Model"
          />
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group
              label="Doküman Türü"
              label-for="id_com_rental_document_types"
            >
              <v-select
                id="id_com_rental_document_types"
                v-model="dataQuery.id_com_rental_document_types"
                placeholder="Doküman Türü"
                :options="typeList"
                :reduce="item => item.id"
                label="title"
                :close-on-select="false"
                multiple
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Stok Durumu"
              label-for="id_com_rent_stock_status"
            >
              <v-select
                id="id_com_rent_stock_status"
                v-model="dataQuery.id_com_rent_stock_status"
                placeholder="Stok Durumu"
                :options="stockStatus"
                :reduce="item => item.id"
                label="title"
                :close-on-select="false"
                multiple
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-col class="text-center">
          <b-button
            variant="primary"

            @click="getDataList"
          >
            <FeatherIcon icon="FilterIcon" />
            Filtrele
          </b-button>
        </b-col>

      </b-card>
    </b-col>
    <b-col md="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Doküman Raporu</b-card-title>
        </b-card-header>
        <div
          v-if="getReportLoading"
          class="text-primary"
        >
          <loading />
        </div>
        <b-table
          v-else
          striped
          hover
          :items="dataList"
          :fields="dataFields"
        >
          <template #cell(trafik_policesi)="data">
            <div
              :class="data.item.trafik_policesi ? 'font-small-2 text-success text-center' : 'font-small-2 text-danger text-center'"
            >
              {{ data.item.trafik_policesi }}
              <b-button
                variant="flat-success"
                class="btn-icon rounded-circle"
                size="sm"
                @click="setModalData(data.item,'1')"
              >

                <feather-icon icon="UploadIcon" />
              </b-button>
            </div>
          </template>
          <template #cell(kasko_policesi)="data">
            <div
              :class="data.item.kasko_policesi ? 'font-small-2 text-success text-center' : 'font-small-2 text-danger text-center'"
            >
              {{ data.item.kasko_policesi }}
              <b-button
                variant="flat-success"
                class="btn-icon rounded-circle"
                size="sm"
                @click="setModalData(data.item,'2')"
              >

                <feather-icon icon="UploadIcon" />
              </b-button>
            </div>
          </template>
          <template #cell(ruhsat)="data">
            <div
              :class="data.item.ruhsat ? 'font-small-2 text-success text-center' : 'font-small-2 text-danger text-center'"
            >
              {{ data.item.ruhsat }}
              <b-button
                variant="flat-success"
                class="btn-icon rounded-circle"
                size="sm"
                @click="setModalData(data.item,'3')"
              >

                <feather-icon icon="UploadIcon" />
              </b-button>
            </div>
          </template>
          <template #cell(kiralama_sozlesmesi)="data">
            <div
              :class="data.item.kiralama_sozlesmesi ? 'font-small-2 text-success text-center' : 'font-small-2 text-danger text-center'"
            >
              {{ data.item.kiralama_sozlesmesi }}
            </div>
          </template>
          <template #cell(damga_vergisi_dosyasi)="data">
            <div
              :class="data.item.damga_vergisi_dosyasi ? 'font-small-2 text-success text-center' : 'font-small-2 text-danger text-center'"
            >
              {{ data.item.damga_vergisi_dosyasi }}
            </div>
          </template>
          <template #cell(noter_satis_dosyasi)="data">
            <div
              :class="data.item.noter_satis_dosyasi ? 'font-small-2 text-success text-center' : 'font-small-2 text-danger text-center'"
            >
              {{ data.item.noter_satis_dosyasi }}
            </div>
          </template>
          <template #cell(iptal_policesi)="data">
            <div
              :class="data.item.iptal_policesi ? 'font-small-2 text-success text-center' : 'font-small-2 text-danger text-center'"
            >
              {{ data.item.iptal_policesi }}
            </div>
          </template>
          <template #cell(sirket_evraklari)="data">
            <div
              :class="data.item.sirket_evraklari ? 'font-small-2 text-success text-center' : 'font-small-2 text-danger text-center'"
            >
              {{ data.item.sirket_evraklari }}
            </div>
          </template>
          <template #cell(diger)="data">
            <div
              :class="data.item.diger ? 'font-small-2 text-success text-center' : 'font-small-2 text-danger text-center'"
            >
              {{ data.item.diger }}
            </div>
          </template>
          <template #cell(car-info)="data">
            <div class="font-small-2 text-danger">
              Araç Kodu: {{ data.item.arackodu }}
            </div>
            <div v-if="data.item.title">
              <b-link
                :to="'/rental/stocks/view/' + data.item.id"
                target="_blank"
              >
                {{ data.item.brand }} {{ data.item.title }}
              </b-link>

              <div class="text-warning font-small-2">
                {{ data.item.chasis }}
              </div>
            </div>
            <div
              v-if="data.item.licence_plate"
              class="text-muted font-small-2"
            >
              {{ data.item.licence_plate }}
              <div class="text-muted font-small-2" />
            </div>
          </template>
        </b-table>
        <b-card-footer
          v-if="!getReportLoading"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCounts"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
        <b-modal
          v-model="dataQuery.uploadModal"
          centered
          title="Doküman Yükle"
          hide-footer
          no-close-on-esc
          no-close-on-backdrop
          size="lg"
        >
          <validation-observer ref="simpleRules">
            <b-form-group
              label="Doküman Tipi"
              label-for="id_com_rental_document_types"
            >
              <validation-provider
                #default="{ errors }"
                name="Doküman Tipi"
                rules="required"
              >
                <v-select
                  v-model="formModal.modalType"
                  :options="rentalDocumentTypes"
                  :reduce="item => item.id"
                  label="title"
                  placeholder="Dosya Tipi"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Tarih Seçimi"
              rules="required"
            >
              <b-row v-if="['1','2'].includes(formModal.modalType)">
                <b-col>
                  <b-form-datepicker
                    id="sdate"
                    v-model="dataItem.sdate"
                    v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                    locale="tr"
                    start-weekday="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-col>
                <b-col>
                  <b-form-datepicker
                    id="edate"
                    v-model="dataItem.edate"
                    v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                    locale="tr"
                    start-weekday="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-col>
              </b-row>
            </validation-provider>
            <b-row v-if="['1','2'].includes(formModal.modalType)">
              <b-col>
                <b-form-group
                  label="Başlangıç Tarihi"
                  label-for="sdate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Başlangıç Tarihi Seçimi"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="sdate"
                      v-model="dataItem.sdate"
                      v-bind="{labelNoDateSelected: 'Başlangıç Tarihi Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                      locale="tr"
                      start-weekday="1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group
                  label="Bitiş Tarihi"
                  label-for="edate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Bitiş Tarihi Seçimi"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="edate"
                      v-model="dataItem.edate"
                      v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                      locale="tr"
                      start-weekday="1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <validation-provider
              #default="{ errors }"
              name="Doküman"
              rules="required"
            >
              <b-form-group
                label="Doküman"
                label-for="upload_file"
              >
                <b-form-file
                  v-model="dataItem.upload_file"
                  placeholder="Bir dosya seçin veya buraya bırakın..."
                  drop-placeholder="Dosya buraya bırakın..."
                  browse-text="Dosya Seçin"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <b-row>
              <b-col class="text-right">
                <div
                  v-if="uploading"
                  class="text-center text-primary"
                >
                  <b-spinner />
                  <div class="mt-1">
                    Lütfen Bekleyiniz...
                  </div>
                </div>
                <b-button
                  v-else
                  variant="danger"
                  @click="submitForm()"
                >
                  Kaydet
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-modal>
      </b-card>
    </b-col>

  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormInput, BButton, BCardFooter, BPagination, BCardHeader, BCardTitle, BModal, BFormFile, BFormDatepicker, BSpinner, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'ReportsRentalDocuments',
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BCardFooter,
    BPagination,
    BCardHeader,
    BCardTitle,
    Loading,
    BModal,
    ValidationObserver,
    ValidationProvider,
    BFormFile,
    BFormDatepicker,
    BSpinner,
    BLink,
  },
  data() {
    return {
      dataQuery: {
        limit: 50,
        start: 0,
        keywords: null,
        id_com_rent_stock_status: [],
        id_com_rental_document_types: [],
        uploadModal: false,
        modalData: [],
      },
      formModal: {
        id_com_rental_document_types: [],
        upload_file: null,
        modalType: null,
        required,
      },
      currentPage: 1,
      perPage: 50,
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['rentalDocumentsReport/dataList']
    },
    dataItem() {
      return this.$store.getters['rentalDocuments/dataItem']
    },
    typeList() {
      return this.$store.getters['rentalDocumentTypes/dataList']
    },
    dataFields() {
      return this.$store.getters['rentalDocumentsReport/dataFields']
    },
    stockStatus() {
      return this.$store.getters['rentalStockStatus/dataList']
    },
    dataCounts() {
      return this.$store.getters['rentalDocumentsReport/dataCounts']
    },
    getReportLoading() {
      return this.$store.getters['rentalDocumentsReport/getReportLoading']
    },
    rentalDocumentTypes() {
      return this.$store.getters['rentalDocumentTypes/dataList']
    },
    uploading() {
      return this.$store.getters['rentalDocuments/uploading']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 50
      this.pagination(page)
    },
  },

  created() {
    localize('tr')
    this.getDataList()
    this.getDocumentTypes()
    this.getStockStatuses()
  },
  methods: {
    setModalData(data, type) {
      this.$store.commit('rentalDocuments/RESET_DATA_ITEM')
      this.dataQuery.modalData = data
      this.dataQuery.uploadModal = true
      this.formModal.modalType = type
      this.dataItem.id_com_rental_stocks = data.id
      this.dataItem.id_com_rental_document_types = type
    },
    getDocumentTypes() {
      this.$store.dispatch('rentalDocumentTypes/getDataList', {
        select: [
          'com_rental_document_types.id        AS id',
          'com_rental_document_types.title     AS title',
        ],
      })
    },
    getStockStatuses() {
      this.$store.dispatch('rentalStockStatus/getDataList', {
        select: [
          'com_rent_stock_status.id          AS id',
          'com_rent_stock_status.title       AS title',
        ],
      })
    },
    getDataList() {
      window.scrollTo(0, 0)
      this.$store.dispatch('rentalDocumentsReport/getReportData', this.dataQuery)
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('rentalDocuments/saveData', this.dataItem)
            .then(res => {
              if (res) {
                this.closeModal()
              }
            })
        }
      })
    },
    closeModal() {
      this.dataQuery.uploadModal = false
      this.getDataList()
    },
  },
}
</script>
